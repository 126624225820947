import { Form } from '@remix-run/react';
import LandingImg from '~/assets/img/landing-image.jpg';
import LoginSVG from '~/assets/svg/login';
import FormStepper from '~/components/FormStepper';
import PropertySearchForm from '~/components/PropertySearchForm';
import { useRootLoaderData } from '~/utils/useRootLoaderData';

export default function Index() {
  const { userProfile } = useRootLoaderData();

  return (
    <>
      {userProfile ? (
        <main className="grow p-8">
          <FormStepper step={0} />
          <PropertySearchForm />
        </main>
      ) : (
        <main className="landing flex grow flex-col-reverse gap-8 p-8 md:flex-row">
          <section className="flex grow flex-col gap-2">
            <h2>Welcome to City of Zen</h2>
            <h1>Simplifying Property Insurance Quotes</h1>
            <h2>Effortless, Accurate, Instant</h2>
            <p>
              Welcome to City Of Zen, the ultimate tool for insurance agents to
              quote property insurance with ease and precision. Embrace the
              tranquility of streamlined processes and accurate results, all at
              your fingertips.
            </p>
            <h2>Why City Of Zen?</h2>
            <h4>Intuitive Interface</h4>
            <p>
              Our user-friendly design ensures you can generate quotes swiftly
              and without hassle. Navigate through the process with ease, and
              focus on what matters most: your clients.
            </p>
            <h4>Accurate Quotes</h4>
            <p>
              City Of Zen leverages advanced algorithms and up-to-date data to
              provide precise property insurance quotes. Say goodbye to manual
              errors and inconsistencies.
            </p>
            <h4>Instant Results</h4>
            <p>
              Time is of the essence. Get instant quotes that help you make
              informed decisions quickly. No more waiting or delays.
            </p>
            <h2>Features</h2>
            <h4>Comprehensive Database</h4>
            <p>
              Access a vast database of property information, ensuring your
              quotes are based on the most reliable and current data available.
            </p>
            <h4>Secure and Compliant</h4>
            <p>
              Time is of the essence. Get instant quotes that help you make
              informed decisions quickly. No more waiting or delays.
            </p>
          </section>
          <aside className="mb-8 flex flex-col items-center gap-2 md:w-4/12 md:shrink-0">
            <img src={LandingImg} className="my-4" alt="Start Quoting" />
            <h2>Login and Start Quoting</h2>
            <Form method="post" action="/auth/login">
              <button className="btn btn-primary btn-lg btn-wide my-4 text-2xl drop-shadow">
                Log In
                <LoginSVG />
              </button>
            </Form>
            <h3>Or Get Started Today</h3>
            <p>
              Transform your quoting process with City Of Zen. Join a community
              of insurance agents who trust us to enhance their efficiency and
              accuracy. Sign up now and experience the Zen of property insurance
              quoting.
            </p>
            <div className="flex items-center">
              <h4 className="mr-2">Not a member?</h4>
              <button className="btn btn-neutral btn-sm text-white drop-shadow">
                Sign Up Now!
              </button>
            </div>
          </aside>
        </main>
      )}
    </>
  );
}
